<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3">
            <div>
                <h4>Purchase Details</h4>
            </div>
            <div class="btn-group">
                <btn @click="$router.push('/warehouse/entry/list/')" size="sm" text="Back"/>
            </div>
        </div>
        <purchase-entry-details-table :entry="entry"/>
        <purchase-order-details :order="order"/>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import PurchaseEntryDetailsTable from './TableComponents/PurchaseEntryDetailsTable';
import PurchaseOrderDetails from './TableComponents/PurchaseOrderDetails';

export default {
    name       : 'PurchaseEntryDetailsPage',
    components : { PurchaseOrderDetails, PurchaseEntryDetailsTable },
    data () {
        return {
            DataLoading : false,
            entry_id    : this.$route.params.id,
            entry       : {},
            order       : {}
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseEntry.details, { entry_id : that.entry_id });
            const json = response.data;
            if (json.error === false) {
                that.entry = json.entry;
                that.order = json.order;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
